import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "TDI Marketing Office, Kundli",
  address: "Kundali, Haryana",
  client: "Taneja Developers Pvt. Ltd.",
  area: "",
  project: "Sales Office",
  category: "Landscape | Architecture",
  backlink: "/projects/landscape/",
};

export default class Projecttdimarketingofficekundli extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Landscape Projects | TDI Marketing Office, Kundli"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the outdoor layout, structural and water features designed for a dedicated sales office for Taneja Developers Pvt. Ltd."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
