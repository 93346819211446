const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects/tdimarketingoffice/pic1.png",
    text: "1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/tdimarketingoffice/pic2.png",
    text: "2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/tdimarketingoffice/pic3.png",
    text: "3",
  },
];

export default data;
